import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { Column } from '../Grid';
import Image from '../Image';
import OutboundLink from '../OutboundLink';
import './styles.scss';

const Activity = ({
  image,
  title,
  grade,
  type,
  body,
  familyActivity,
  familyActivitySize,
  lessonPlan,
  lessonPlanSize,
  studentActivity,
  studentActivitySize,
}) => (
  <Column size={4}>
    <div className="activity-card box-shadow hover imagezoom">
      <Image filename={image} alt={title} className="activity-card__image" />
      <div className="activity-card__content">
        <h3 className="activity-card__title">{title}</h3>
        <div className="activity-card__tags">
          <h4 className="activity-card__grade">{grade}</h4>
          <h4 className="activity-card__type">{type}</h4>
        </div>
        <p>{body}</p>
      </div>
      <div className="activity-card__files">
        <div className="activity-card__activity">
          <OutboundLink to={familyActivity}>
            Family Activity <Icon name="download" />
          </OutboundLink>
          <span className="activity-card__filesize">{familyActivitySize}</span>
        </div>
        <div className="activity-card__lesson">
          <OutboundLink to={lessonPlan} className="button bg-gradient-purple">
            Classroom Activity <Icon name="download" />
          </OutboundLink>
          <span className="activity-card__filesize mt-0.5">
            {lessonPlanSize}
          </span>
        </div>
        <div className="activity-card__student">
          <OutboundLink
            to={studentActivity}
            className="button bg-gradient-purple"
          >
            Student Activity <Icon name="download" />
          </OutboundLink>
          <span className="activity-card__filesize mt-0.5">
            {studentActivitySize}
          </span>
        </div>
      </div>
    </div>
  </Column>
);

Activity.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  grade: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  familyActivity: PropTypes.string,
  familyActivitySize: PropTypes.string,
  lessonPlan: PropTypes.string,
  lessonPlanSize: PropTypes.string,
  studentActivity: PropTypes.string,
  studentActivitySize: PropTypes.string,
};

export default Activity;
